import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { AngebotAbhlenenDialogBoxComponent } from 'src/app/component/dialog-boxes/angebot-abhlenen-dialog-box/angebot-abhlenen-dialog-box.component';
import { Status } from 'src/app/models/user';
import { AdminService } from 'src/app/services/admin.service';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import { NgIf } from '@angular/common';
import { TranslationDirective } from '../../../component/translata-modal/translation.directive';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'app-error-page',
    templateUrl: './error-page.component.html',
    styleUrls: ['./error-page.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        TranslationDirective,
        TranslateModule,
        MatButton,
    ],
})
export class ErrorPageComponent implements OnInit {
  isErrorPage = false;
  isNoResult = false;
  isNoAccepted = false;
  isNoIdentity = false;
  isLastNoIdentity = false;
  errorMessage: string;
  canbeDisapled: boolean;
  errMsg: string;

  constructor(
    private router: Router,
    public auth: AuthService,
    public dialog: MatDialog,
    private adminService: AdminService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.incrementErrorCounter();
    this.errorMessage = this.auth.user?.finapi?.payload?.errorMessage || '';
    this.errMsg = 'Zugang zu Ihrem Bestandskonto ist aktuell nicht möglich';
    switch (this.router.url) {
      /*Add all use cases for error handaling*/
      case '/info/no-result-page': {
        this.isNoResult = true;
        break;
      }
      case '/info/not-accepted': {
        this.isNoAccepted = true;
        break;
      }
      case '/info/identity-error': {
        this.isNoIdentity = true;
        break;
      }
      case '/info/last-identity-error': {
        this.isLastNoIdentity = true;
        break;
      }
      default: {
        this.isErrorPage = true;
        break;
      }
    }
  }

  acceptOffer() {
    const currentUser = this.auth.user;
    if (currentUser.finapi.counter === 3) {
      this.auth.updateAuswahlProdukt(this.auth.user._id).subscribe({
        next: () => {
          this.auth.updateQuestions(this.auth.user._id).subscribe({
            next: () => {
              this.auth.refreshUser().subscribe();
              this.canbeDisapled = true;
              setTimeout(() => {
                this.router.navigateByUrl('/');
              }, 5000);
            },
          });
        },
      });
    }
  }

  goBack() {
    if (this.auth.user.finapi.counter === 3) {
      this.openPopUp();
    } else {
      this.router.navigateByUrl('/');
    }
  }

  openPopUp() {
    this.dialog
      .open(AngebotAbhlenenDialogBoxComponent)
      .afterClosed()
      .subscribe(async (answer) => {
        if (answer == true) {
          this.adminService.updateSubStatus(this.auth.user._id, 'offeringRejected').subscribe(() => {
            this.auth.changeStatus(this.auth.user._id, Status.inactive).subscribe(() => {
              window.location.href = environment.oneDashboardURL;
            });
          });
          this.router.navigateByUrl('/');
        }
      });
  }

  //We Will Need It later on
  incrementErrorCounterIfChanged() {
    const { user } = this.auth;
    const { finapi } = user;
    const { payload, counter } = finapi;

    if (!payload || payload.errorCode === '') {
      return counter;
    }

    if (payload.errorCode === 'BANK_SERVER_REJECTION') {
      finapi.counter = 3;
    } else if (!counter || counter !== 3) {
      finapi.counter = counter ? counter + 1 : 1;
    }

    this.auth.updateUser(user._id, finapi.counter).subscribe();

    return finapi.counter;
  }

  incrementErrorCounter() {
    const currentUser = this.auth.user;
    currentUser.finapi.counter = 3;
    this.auth.updateUser(this.auth.user._id, currentUser.finapi.counter).subscribe();

    return currentUser.finapi.counter;
  }
}
