import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { NotificationService } from 'src/app/services/notification.service';
import { QuestionService } from 'src/app/services/question.service';

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService, TranslateModule } from '@ngx-translate/core';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PdfViewBoxComponent } from 'src/app/component/pdf-view-box/pdf-view-box.component';
import { QuestionTemplate } from '../question-template';
import { MatSort } from '@angular/material/sort';
import { Rol } from 'src/app/models/user';
import { FinapiService } from 'src/app/services/finapi.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslationDirective } from '../../../../component/translata-modal/translation.directive';
import { NgIf, NgFor, KeyValuePipe } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle } from '@angular/material/expansion';
import { MatTable, MatColumnDef, MatCellDef, MatCell, MatRowDef, MatRow } from '@angular/material/table';
import { MatButton } from '@angular/material/button';
import { MatRadioGroup, MatRadioButton } from '@angular/material/radio';
import { MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
export interface Dokument {
  name: string;
  position: number;
}

const AGBS_DATA: Dokument[] = [
  { name: 'Allgemeine Geschäftsbedingungen', position: 1 },
  { name: 'Sonderbedingungen für das Online-Banking', position: 2 },
  { name: 'Sonderbedingungen für den Lastschriftverkehr', position: 3 },
  { name: 'Sonderbedingungen für den Überweisungsverkehr', position: 4 },
  {
    name: 'Sonderbedingungen für die Ausführung von Echtzeit-Überweisungen',
    position: 5,
  },
  {
    name: 'Sonderbedingungen für die Ausführung von Echtzeit-Überweisungen mittels Sammelaufträgen',
    position: 6,
  },
  { name: 'Sonderbedingungen für die girocard (Debitkarte)', position: 7 },
  {
    name: 'Sonderbedingungen für die Nutzung des elektronischen Postfachs',
    position: 8,
  },
];
const DATENSCHUTZ_DATA: Dokument[] = [{ name: 'Datenschutzhinweise', position: 8 }];
const INFOEINLEGER_DATA: Dokument[] = [{ name: 'Informationsbogen für den Einleger', position: 9 }];
@Component({
    selector: 'app-blanko-dokumente',
    templateUrl: './blanko-dokumente.component.html',
    styleUrls: ['./blanko-dokumente.component.scss'],
    standalone: true,
    imports: [
        TranslationDirective,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        NgIf,
        MatIcon,
        MatCheckbox,
        MatAccordion,
        MatExpansionPanel,
        MatExpansionPanelHeader,
        MatExpansionPanelTitle,
        MatTable,
        MatColumnDef,
        MatCellDef,
        MatCell,
        MatButton,
        MatRowDef,
        MatRow,
        MatRadioGroup,
        MatRadioButton,
        MatFormField,
        MatInput,
        NgFor,
        KeyValuePipe,
    ],
})
export class BlankoDokumenteComponent extends QuestionTemplate implements OnInit {
  isAdmin: Boolean;
  juriPerson: boolean = false;
  agbDataSource = AGBS_DATA;
  datenschutzDataSource = DATENSCHUTZ_DATA;
  infoEinlegerDataSource = INFOEINLEGER_DATA;
  displayedColumns: string[] = ['name', 'download'];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  constructor(
    auth: AuthService,
    questionService: QuestionService,
    route: ActivatedRoute,
    private dataService: DataService,
    private notificationService: NotificationService,
    private tranlate: TranslateService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<any>,
    private finapiService: FinapiService
  ) {
    super(auth, questionService, route);
    this.questionName = 'blanko-dokumente';
  }

  /**
   * Init variables und FormGroup machen
   */
  override ngOnInit() {
    super.ngOnInit();
    this.isAdmin = this.auth.user.rol == Rol.admin || this.auth.user.rol == Rol.adminBB;
    this.answerFormGroup = new FormGroup({
      hinweise: new FormControl(null, [Validators.requiredTrue]),
      nurDeSteuerpflicht: new FormControl(null, [Validators.requiredTrue]),
      usSteuerpflicht: new FormControl(null),
      agbs: new FormControl(null, [Validators.requiredTrue]),
      datenschutz: new FormControl(null, [Validators.requiredTrue]),
      offentlichesAmt: new FormControl(null, [Validators.required]),
      offentlichesAmtField: new FormControl({ value: '', disabled: true }, null),
      einlagensicherung: new FormControl(null, [Validators.requiredTrue]),
      schufaZustimmung: new FormControl(null, [Validators.requiredTrue]),
    });
    if (!this.auth.isJurPerson()) {
      this.answerFormGroup.controls['usSteuerpflicht'].setValue(false);
      this.answerFormGroup.controls['usSteuerpflicht'].setValidators([Validators.requiredTrue]);
      this.answerFormGroup.controls['usSteuerpflicht'].updateValueAndValidity();
    }
    this.loadValues();
    this.juriPerson = this.auth.isJurPerson();
    this.finapiService.getProccessToken().subscribe(
      (res) => {
        void 0;
      },
      (error) => {
        if (error instanceof HttpErrorResponse) {
          console.log('Response as text:');
        }
      }
    );
  }

  openPdf(documentNr: number) {
    this.dataService.getRechtlichesDocument(documentNr).subscribe((res) => {
      const blob = new Blob([res], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      this.dialogRef = this.dialog.open(PdfViewBoxComponent, {
        data: { pdfSrc: url },
      });

      this.dialogRef.afterClosed().subscribe(() => {});
    });
  }

  adminCheckAll() {
    const controls = this.answerFormGroup.controls;
    controls['hinweise'].setValue(true);
    controls['nurDeSteuerpflicht'].setValue(true);
    controls['usSteuerpflicht'].setValue(true);
    controls['agbs'].setValue(true);
    controls['datenschutz'].setValue(true);
    controls['offentlichesAmt'].setValue(false);
    controls['einlagensicherung'].setValue(true);
    controls['schufaZustimmung'].setValue(true);
  }

  radioPepValidate() {
    const offentlichesAmt = this.answerFormGroup.controls['offentlichesAmt'];
    const offentlichesAmtField = this.answerFormGroup.controls['offentlichesAmtField'];

    if (offentlichesAmt.value === 'true') {
      offentlichesAmtField.enable();
      offentlichesAmtField.setValidators([Validators.required, Validators.minLength(1)]);
    } else {
      offentlichesAmtField.disable();
      offentlichesAmtField.clearValidators();
      offentlichesAmtField.setValue('');
    }

    offentlichesAmtField.updateValueAndValidity();
  }

  getControlTranslationKey(controlKey: string): string {
    switch (controlKey) {
      case 'hinweise':
        return 'RECHTLICHES.RECHTLICHE_TITLE';
      case 'nurDeSteuerpflicht':
      case 'usSteuerpflicht':
        return 'RECHTLICHES.SELBSTAUSKUNFT_TITLE';
      case 'agbs':
        return 'RECHTLICHES.ALLGEMEINE_TITLE';
      case 'datenschutz':
        return 'RECHTLICHES.DATENSCHUTZWEISE_TITLE';
      case 'offentlichesAmt':
        return 'RECHTLICHES.ERKLAERUNG_TITLE';
      case 'einlagensicherung':
        return 'RECHTLICHES.HINWEIS_TITLE';
      case 'schufaZustimmung':
        return 'RECHTLICHES.SCHUFA_TITLE';
      default:
        return '';
    }
  }
}
