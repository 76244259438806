import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, takeUntil, tap, timer } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AgreeJurPersonObj, AgreeNatPersonObj, RoleCall } from '../models/agree';
import { Wirtschaftzweig } from '../types/mappingEvaDifa';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class Agree21Service {
  PERSONEN_NUMMER;
  IBAN;
  agreeNatPersonObj = new AgreeNatPersonObj();
  agreeJurPersonObj = new AgreeJurPersonObj();

  constructor(
    private auth: AuthService,
    private http: HttpClient
  ) {
    this.IBAN = this.auth.user?.iban;
    this.PERSONEN_NUMMER = this.auth.user?.kundenNummer;
  }

  public postNatPersonKontokorrent(agreeNatPersonObj: AgreeNatPersonObj, creditLimitAmount: number): Observable<any> {
    const timeout$ = timer(120000).pipe(tap(() => console.error('Request timed out')));
    const response = this.http
      .post(`${environment.apiURL}/agree21api/natPersonKontokorrent`, {
        agreeNatPersonObj: agreeNatPersonObj,
        creditLimitAmount: creditLimitAmount,
        steuerID: '',
        _id: this.auth.user._id,
      })
      .pipe(takeUntil(timeout$));

    return response;
  }

  public postNatPersonen(creditLimitAmount: number, isGbr: boolean): Observable<any> {
    const response = this.http.post(`${environment.apiURL}/agree21api/createNatPersonen`, {
      creditLimitAmount: creditLimitAmount,
      steuerID: '',
      _id: this.auth.user._id,
      env: environment.name,
      isGbr: isGbr,
    });
    return response;
  }

  public postJurPerson(agreeJurPersonObj: AgreeJurPersonObj, creditLimitAmount: number): Observable<any> {
    const timeout$ = timer(200000).pipe(tap(() => console.error('Request timed out')));

    const response = this.http
      .post(`${environment.apiURL}/agree21api/createJurPerson`, {
        agreeJurPersonObj: agreeJurPersonObj,
        creditLimitAmount: creditLimitAmount,
        steuerID: '',
        _id: this.auth.user._id,
      })
      .pipe(takeUntil(timeout$));

    return response;
  }

  public postGbRPerson(agreeNatPersonObj: AgreeNatPersonObj, creditLimitAmount: number): Observable<any> {
    const timeout$ = timer(200000).pipe(tap(() => console.error('Request timed out')));

    const response = this.http
      .post(`${environment.apiURL}/agree21api/createGbrPerson`, {
        agreeNatPersonObj: agreeNatPersonObj,
        creditLimitAmount: creditLimitAmount,
        steuerID: '',
        _id: this.auth.user._id,
      })
      .pipe(takeUntil(timeout$));

    return response;
  }

  async postVollmacht(agreeVollmacht, JurPersonennummer): Promise<Observable<any>> {
    try {
      const response = await this.http
        .post(`${environment.apiURL}/agree21api/createVollmacht`, {
          agreeVollmacht,
          JurPersonennummer,
          _id: this.auth.user._id,
        })
        .toPromise();

      return new Observable<any>((observer) => {
        observer.next(response);
        observer.complete();
      });
    } catch (error) {
      return new Observable<any>((observer) => {
        observer.error(error);
        observer.complete();
      });
    }
  }

  public async vollmachtCall(user) {
    const index = this.auth.findFlaggedNaturalPerson(user.natPersonen);
    const today = new Date().toISOString().substr(0, 10);
    const BBKundennummer = user.natPersonen[index].BBKundennummer;
    const JurBBKundennummer: number = user.JurPerson.JurDaten.BBKundennummer;

    return {
      Gueltigkeitsbeginn: today,
      Gueltigkeitsende: '9999-11-17',
      ElektronischeDokumentation: true,
      Kundenvollmacht: true,
      VerfuegungsRechte: 'Einzelvollmacht',
      Vollmachtsgrund: 'Geschäftsführer',
      BerechtigungsnehmerID: BBKundennummer,
      BerechtigungsgeberID: JurBBKundennummer,
    };
  }

  public girocardOnlineBankingForJurPerson(Personennummer, iban, wunschAlias): Observable<any> {
    return this.http.post(`${environment.apiURL}/agree21api/girocardOnlineBankingForJurPerson`, {
      Personennummer,
      iban,
      wunschAlias,
      _id: this.auth.user._id,
    });
  }

  public postGirocardOnlineBanking(Personennummer, iban, wunschAlias): Observable<any> {
    return this.http.post(`${environment.apiURL}/agree21api/girocardOnlineBanking`, {
      Personennummer,
      iban,
      wunschAlias,
      _id: this.auth.user._id,
    });
  }
  public async buildAgreeObj() {
    let natPerson = this.auth.user.natPersonen[this.auth.index];
    let lang = this.auth.user.lang;
    //Nat
    this.agreeNatPersonObj.NatDaten = {
      Personenart: natPerson.Person.Personenart,
      Kundenart: natPerson.Person.Kundenart,
      Anrede: natPerson.Person.Anrede,
      Titel: natPerson.Person.Titel,
      Vorname: natPerson.Person.Vorname,
      Nachname: natPerson.Person.Nachname,
      Briefanrede: natPerson.Person.Briefanrede,
      Geburtsname: natPerson.Person.Geburtsname,
      Geburtsdatum: natPerson.Person.Geburtsdatum,
      Geburtsort: natPerson.Person.Geburtsort?.slice(0, 28),
      Geburtsland: natPerson.Person.Geburtsland,
      RechtsformAuspraegung: natPerson.Person.RechtsformAuspraegung,
    };
    //Vertragsdaten
    this.agreeNatPersonObj.Vertragsdaten = {
      Stammvertrag: 'Kundenstammvertrag Einzelkonto',
      NrBerater: environment.agree21.Vertragsdaten.NrBerater,
      Kundensegment:
        lang === 'mcc'
          ? environment.agree21.Vertragsdaten.KundensegmentMcc
          : environment.agree21.Vertragsdaten.KundensegmentOdfin,
    };

    //wirtschaftsdaten
    const matchingQuestion = this.auth.user.questions.find((q) => q.name === 'zusammenfassung');

    const steuerId = matchingQuestion && matchingQuestion.answer ? matchingQuestion.answer.steuerId : '';

    this.agreeNatPersonObj.Wirtschaftsdaten = {
      Wirtschaftszweig: natPerson.BranchenInfo.Fachgebiet,
      Berufe: {
        Beruf: [
          {
            Berufsbezeichnung: '',
            Berufsgruppe:
              lang === 'mcc'
                ? environment.agree21.Wirtschaftsdaten.BerufsgruppeMcc
                : environment.agree21.Wirtschaftsdaten.BerufsgruppeOdfin,
            selbststaendig: true,
          },
        ],
      },
      SteuerID: '',
      GWGRisikoklasse: 'Normales Risiko',
    };

    //Legi

    this.agreeNatPersonObj.Legitimationsdaten = {
      Ausweisart: natPerson.Legitimationsdaten.Ausweisart,
      Ausweisnummer: natPerson.Legitimationsdaten.Ausweisnummer,
      AusstellerLand: natPerson.Legitimationsdaten.AusstellerLand,
      AusstellerBehoerde: natPerson.Legitimationsdaten.AusstellerBehoerde,
      AusgestelltAm: natPerson.Legitimationsdaten.AusgestelltAm,
      GueltigBis: natPerson.Legitimationsdaten.GueltigBis,
      GeprueftAm: natPerson.Legitimationsdaten.GeprueftAm,
      GeprueftVonID: environment.agree21.Legitimationsdaten.GeprueftVonID,
      AusfOrganisation: environment.agree21.Legitimationsdaten.AusfOrganisation,
      ausweiskopieVorh: true,
      Staatsangehoerigkeit: natPerson.Legitimationsdaten.AusstellerLand,
    };

    //Adresse
    this.agreeNatPersonObj.Adresse = natPerson.Adresse;
    //Kontaktdaten

    const zusammenfassungQuestion = this.auth.user.questions.find((q) => q.name === 'zusammenfassung');
    const telefonValue = zusammenfassungQuestion ? zusammenfassungQuestion.answer.telefon : null;

    this.agreeNatPersonObj.Kontaktdaten = {
      Kontakt: [
        {
          LaufendeNummer: 1,
          Kontaktart: 'Geschäftlich',
          Kontaktmedium: 'Telefon',
          Wert: telefonValue,
        },
        {
          LaufendeNummer: 2,
          Kontaktart: 'Geschäftlich',
          Kontaktmedium: 'E-Mail',
          Wert: natPerson.Kontakt.Email,
        },
      ],
    };
  }

  public async buildAgreeJurPersonObj() {
    const natPerson = this.auth.user.natPersonen[this.auth.index];
    const jurPerson = this.auth.user.JurPerson;
    const { JurDaten, Registerdaten } = jurPerson;
    const lang = this.auth.user.lang;

    const truncatedFirmenname = JurDaten.Firmenname?.slice(0, 150);

    const kurzname = JurDaten.Kurzname ? JurDaten.Kurzname?.slice(0, 31) : JurDaten.Firmenname?.slice(0, 31);

    this.agreeJurPersonObj.JurDaten = {
      Personenart: JurDaten.Personenart,
      Kundenart: JurDaten.Kundenart,
      Anrede: JurDaten.Anrede,
      Briefanrede: 'Sehr geehrte Damen und Herren,',
      Firmenname: truncatedFirmenname,
      Kurzname: kurzname,
      RechtsformAuspraegung: JurDaten.RechtsformAuspraegung,
      GbrArt: JurDaten.GbrArt,
      Gruendungsland: 'DE',
      Gruendungsort: '',
      Gruendungsdatum: Registerdaten?.EintragDatum,
      NiederlassungLand: 'DE',
      AnzMitarbeiter: 0, // TODO Check Task #111 * Muss aus Crefo.xml ( muss von BB defniert von wo ich das bekommen soll ( vermutlich staffcompany ) )
      StandAnzMitarbeiter: new Date().toISOString().substr(0, 10), //TODO Muss aus Crefo.xml
    };

    const Geschaeftsstelle = lang === 'mcc' ? 0 : 5;
    this.agreeJurPersonObj.Vertragsdaten = {
      Stammvertrag: 'Kundenstammvertrag Einzelkonto',
      Stammvertragsdatum: new Date().toISOString().substr(0, 10),
      Geschaeftsstelle: Geschaeftsstelle,
      //NrKundenbearbeiter: 0,
      NrBerater: environment.agree21.Vertragsdaten.NrBerater,
      Kundensegment:
        lang === 'mcc'
          ? environment.agree21.Vertragsdaten.KundensegmentMcc
          : environment.agree21.Vertragsdaten.KundensegmentOdfin,
    };

    const branchenInfoFachgebiet = natPerson.BranchenInfo.Fachgebiet;

    let matchingNumericalCode = 0;
    for (const [numericalCode, description] of Wirtschaftzweig) {
      if (description === branchenInfoFachgebiet) {
        matchingNumericalCode = numericalCode;
        break;
      }
    }

    this.agreeJurPersonObj.Wirtschaftsdaten = {
      Wirtschaftszweig: natPerson.BranchenInfo.Fachgebiet,
      WirtschaftszweigNumerisch: matchingNumericalCode,
      WZAnteil: 100,
      SteuerID: '',
      GWGRisikoklasse: 'Normales Risiko',
    };

    this.agreeJurPersonObj.Registerdaten = {
      Registerart: Registerdaten?.Registerart,
      Registergericht: Registerdaten?.Registergericht,
      Registernummer: Registerdaten?.Registernummer,
      EintragDatum: Registerdaten?.EintragDatum,
      RegAuszugDatum: Registerdaten?.RegAuszugDatum,
      RegGeprueftVon: environment.agree21.Legitimationsdaten.GeprueftVonID,
      RegGeprueftAm: Registerdaten?.RegGeprueftAm,
    };

    this.agreeJurPersonObj.Adresse = natPerson.Firmenanschrift;

    this.agreeJurPersonObj.Kontaktdaten = {
      Kontakt: [
        {
          LaufendeNummer: 1,
          Kontaktart: 'Geschäftlich',
          Kontaktmedium: 'Telefon',
          Wert: jurPerson.Kontaktdaten.Kontakt.Telefon,
        },
        {
          LaufendeNummer: 2,
          Kontaktart: 'Geschäftlich',
          Kontaktmedium: 'E-Mail',
          Wert: jurPerson.Kontaktdaten.Kontakt.Email,
        },
      ],
    };
  }
  public async buildAgreeGbRPersonObj() {
    const natPerson = this.auth.user.natPersonen[this.auth.index];
    const jurPerson = this.auth.user.JurPerson;
    const { JurDaten } = jurPerson;
    const lang = this.auth.user.lang;

    const kurzname = JurDaten.Kurzname ? JurDaten.Kurzname?.slice(0, 31) : JurDaten.Firmenname?.slice(0, 31);

    this.agreeNatPersonObj.NatDaten = {
      Personenart: JurDaten.Personenart,
      Kundenart: JurDaten.Kundenart,
      Anrede: 'Keine Anrede',
      Briefanrede: 'Sehr geehrte Damen und Herren,',
      RechtsformAuspraegung: JurDaten.RechtsformAuspraegung,
      Geburtsland: 'DE',
      Vorname: 'Firma',
      Nachname: kurzname,
      Geburtsdatum: '2000-01-01',
    };

    this.agreeNatPersonObj.Vertragsdaten = {
      Stammvertrag: 'Konten/Depots GbR',
      NrBerater: environment.agree21.Vertragsdaten.NrBerater,
      Geschaeftsstelle: lang === 'mcc' ? 0 : 5,
      Kundensegment:
        lang === 'mcc'
          ? environment.agree21.Vertragsdaten.KundensegmentMcc
          : environment.agree21.Vertragsdaten.KundensegmentOdfin,
    };

    const branchenInfoFachgebiet = natPerson.BranchenInfo.Fachgebiet;

    let matchingNumericalCode = 0;
    for (const [numericalCode, description] of Wirtschaftzweig) {
      if (description === branchenInfoFachgebiet) {
        matchingNumericalCode = numericalCode;
        break;
      }
    }

    (this.agreeNatPersonObj.Legitimationsdaten = {
      Staatsangehoerigkeit: 'DE',
      Ausweisart: 'Sonstige Bezeichnung',
      sonstBez: 'Gesellsch.Vertrag',
      Ausweisnummer: '-',
      AusstellerLand: 'DE',
      AusstellerBehoerde: 'Aus Gesellschaftsvertrag zu entnehmen',
      AusgestelltAm: '2000-01-01',
      GueltigBis: '9999-12-31',
      GeprueftAm: new Date().toISOString().substr(0, 10),
      GeprueftVonID: environment.agree21.Legitimationsdaten.GeprueftVonID,
      AusfOrganisation: 'Bankfiliale',
    }),
      (this.agreeNatPersonObj.Wirtschaftsdaten = {
        Wirtschaftszweig: 'Sonstige selbstständige Tätigkeiten im Gesundheitswesen',
        GWGRisikoklasse: 'Geringes Risiko',
      });

    this.agreeNatPersonObj.Adresse = natPerson.Firmenanschrift;

    this.agreeNatPersonObj.Kontaktdaten = {
      Kontakt: [
        {
          LaufendeNummer: 1,
          Kontaktart: 'Geschäftlich',
          Kontaktmedium: 'Telefon',
          Wert: jurPerson.Kontaktdaten.Kontakt.Telefon,
        },
        {
          LaufendeNummer: 2,
          Kontaktart: 'Geschäftlich',
          Kontaktmedium: 'E-Mail',
          Wert: jurPerson.Kontaktdaten.Kontakt.Email,
        },
      ],
    };
  }
  async postJurRollen(agreeJurRollen, Personennummer): Promise<Observable<any>> {
    try {
      const postBody = {
        agreeJurRollen,
        Personennummer,
        _id: this.auth.user._id,
      };

      const response = await this.http
        .post(`${environment.apiURL}/agree21api/createJurPersonRollen`, postBody)
        .toPromise();

      return new Observable<any>((observer) => {
        observer.next(response);
        observer.complete();
      });
    } catch (error) {
      return new Observable<any>((observer) => {
        observer.error(error);
        observer.complete();
      });
    }
  }

  public async JurPersonRollenCall(user): Promise<RoleCall[]> {
    const roleCalls: RoleCall[] = [];

    for (const natPerson of user.natPersonen) {
      const Position: string = natPerson.Person.Position;
      const BBKundennummer = natPerson.BBKundennummer;
      const JurBBKundennummer: number = user.JurPerson.JurDaten.BBKundennummer;

      if (
        Position === 'Gesellschafter/-in' ||
        Position === 'Geschäftsführende/r Gesellschafter/-in' ||
        Position === 'Inhaber/-in'
      ) {
        roleCalls.push(this.createGesellschafterRoleCall(JurBBKundennummer, BBKundennummer));
      }
      if (
        Position === 'Geschäftsführer/-in' ||
        Position === 'Geschäftsführende/r Gesellschafter/-in' ||
        Position === 'vertretungsbevollmächtigter GF' ||
        Position === 'Vorsitzender der Geschäftsführung'
      ) {
        roleCalls.push(this.createGeschäftsführerRoleCall(JurBBKundennummer, BBKundennummer));
      }
      if (natPerson.Person.Wirtschaftlichberechtigt) {
        roleCalls.push(this.wirtschaftlichberechtigtRoleCall(JurBBKundennummer, BBKundennummer));
      }
      if (natPerson.Person.FiktivWirtschaftlichberechtigt) {
        roleCalls.push(this.createFiktivWirtschaftlichberechtigtRoleCall(JurBBKundennummer, BBKundennummer));
      }
    }

    return roleCalls;
  }

  private createGesellschafterRoleCall(JurBBKundennummer: number, BBKundennummer: number) {
    if (this.auth.isRechtsformGBR()) {
      return {
        NehmerID: BBKundennummer,
        GeberID: JurBBKundennummer,
        RollenID: '',
        Bemerkung: '',
        Rollenbezeichnung: 'GbR-Gesellschafter, ist GbR-Gesellschafter von',
        RollenCode: 6,
      };
    } else {
      return {
        NehmerID: BBKundennummer,
        GeberID: JurBBKundennummer,
        RollenID: '',
        Bemerkung: '',
        Rollenbezeichnung: 'GmbH-Gesellschafter, ist GmbH-Gesellschafter von',
        RollenCode: 7,
      };
    }
  }

  private createGeschäftsführerRoleCall(JurBBKundennummer: number, BBKundennummer: number) {
    return {
      NehmerID: BBKundennummer,
      GeberID: JurBBKundennummer,
      RollenID: '',
      Bemerkung: '',
      Rollenbezeichnung: 'Geschäftsführer, ist Geschäftsführer von',
      RollenCode: 1,
    };
  }

  private wirtschaftlichberechtigtRoleCall(JurBBKundennummer: number, BBKundennummer: number) {
    const roleCall = {
      NehmerID: BBKundennummer,
      GeberID: JurBBKundennummer,
      RollenID: '',
      Bemerkung: 'Es handelt sich hierbei um einen wirtschaftlich Berechtigten',
      Rollenbezeichnung: 'Wirtschaftlich Berechtigter GWG (gesellschaftsrechtlicher) von',
      RollenCode: 86,
    };
    return roleCall;
  }

  private createFiktivWirtschaftlichberechtigtRoleCall(JurBBKundennummer: number, BBKundennummer: number) {
    const roleCall = {
      NehmerID: BBKundennummer,
      GeberID: JurBBKundennummer,
      RollenID: '',
      Bemerkung: 'Es handelt sich hierbei um einen fiktiv wirtschaftlich Berechtigten',
      Rollenbezeichnung: 'Wirtschaftlich Berechtigter GWG (gesellschaftsrechtlicher) von',
      RollenCode: 86,
    };
    return roleCall;
  }
}
