<div class="col-12 text-center mt-5">
  <div *ngIf="isErrorPage">
    <h3 *ngIf="auth.user.finapi.counter !== 3" appTranslationModal>
      {{ errorMessage }}
    </h3>
    <h3 *ngIf="auth.user.finapi.counter === 3" appTranslationModal>
      {{ errMsg }}
    </h3>
    <p *ngIf="auth.user.finapi.counter === 1" appTranslationModal [translate]="'ERRORPAGE.Versuch1'"></p>
    <p *ngIf="auth.user.finapi.counter === 2" appTranslationModal [translate]="'ERRORPAGE.Versuch2'"></p>
    <p *ngIf="auth.user.finapi.counter === 3" appTranslationModal [translate]="'ERRORPAGE.Versuch3'"></p>
  </div>
  <div *ngIf="isNoResult">
    <p appTranslationModal [translate]="'ERRORPAGE.NO_RESULT'"></p>
  </div>
  <div *ngIf="isNoAccepted">
    <h3 appTranslationModal [translate]="'ERRORPAGE.NOT_ACCEPTED'"></h3>
    <p appTranslationModal [translate]="'ERRORPAGE.NOT_ACCEPTED_SUBTEXT'"></p>
  </div>
  <div *ngIf="isNoIdentity">
    <h3 appTranslationModal [translate]="'ERRORPAGE.NO_IDENTITY'"></h3>
    <p appTranslationModal [translate]="'ERRORPAGE.NO_IDENTITY_SUBTEXT'"></p>
    <!-- <a href="tel:01234-5678">01234-5678</a> -->
  </div>
  <div *ngIf="isLastNoIdentity">
    <h3 appTranslationModal [translate]="'ERRORPAGE.LAST_NO_IDENTITY'"></h3>
    <p appTranslationModal [translate]="'ERRORPAGE.LAST_NO_IDENTITY_SUBTEXT'"></p>
  </div>
  <div style="display: flex; flex-direction: row; justify-content: space-around">
    <button mat-stroked-button (click)="goBack()" class="mt-5" color="accent">Abbrechen</button>

    <button
      *ngIf="auth.user.finapi.counter === 3"
      mat-stroked-button
      [disabled]="canbeDisapled"
      (click)="acceptOffer()"
      class="mt-5"
      color="accent"
    >
      Weiter
    </button>
  </div>
</div>
