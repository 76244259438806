import { saveAs } from 'file-saver';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { QuestionService } from 'src/app/services/question.service';
import { environment } from 'src/environments/environment';

import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { load } from '@finapi/web-form';
import { firstValueFrom } from 'rxjs';
import { DownloadDialogBoxComponent } from 'src/app/component/dialog-boxes/download-dialog-box/download-dialog-box.component';
import { PdfViewBoxComponent } from 'src/app/component/pdf-view-box/pdf-view-box.component';
import { Rol } from 'src/app/models/user';
import { AdminService } from 'src/app/services/admin.service';
import { FinapiService } from 'src/app/services/finapi.service';
import { QuestionTemplate } from '../question-template';
import { NgIf, NgStyle } from '@angular/common';
import { MatCardImage } from '@angular/material/card';
import { TranslationDirective } from '../../../../component/translata-modal/translation.directive';
import { TranslateModule } from '@ngx-translate/core';
import { LoaderComponent } from '../../../../component/loader/loader.component';
import { BankacountsComponent } from './bankacounts/bankacounts.component';
import { KreditcheckabschlussComponent } from './kreditcheckabschluss/kreditcheckabschluss.component';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle } from '@angular/material/expansion';
import { MatTable, MatColumnDef, MatCellDef, MatCell, MatRowDef, MatRow } from '@angular/material/table';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatCheckbox } from '@angular/material/checkbox';

export interface Dokument {
  name: string;
  position: number;
}

const VERTRAEGE_DATA: Dokument[] = [
  { name: 'Elektronisches Postfach', position: 1 },
  { name: 'Girocard', position: 2 },
  // { name: 'Informationsbogen fuer den Einleger', position: 3 },
  { name: 'Kundenstammvertrag fuer jur. Person', position: 4 },
  { name: 'Onlinebanking', position: 5 },
  { name: 'Kreditvertrag Kontokorrentkredite', position: 6 },
  { name: 'Kreditkartenantrag Mastercard', position: 7 },
  { name: 'SEPA Firmenlastschrift', position: 8 },
  { name: 'Selbstauskunft', position: 9 },
  { name: 'GWG Vertragspartner', position: 10 },
  { name: 'GWG Auftretender', position: 11 },
  { name: 'Kundenstammvertrag GbR', position: 12 },
  { name: 'Unterschriftsproben GbR', position: 13 },
  { name: 'Kundenstamm Vertrag', position: 14 },
  { name: 'Abtretungsvertrag', position: 15 },
];

const DOKUMENTE_DATA: Dokument[] = [
  { name: 'Datenschutzhinweise', position: 7 },
  { name: 'Informationsbogen fuer den Einleger', position: 8 },
  { name: 'SCHUFA Klausel', position: 9 },
];

const AGBS_DATA: Dokument[] = [
  { name: 'Allgemeine Geschäftsbedingungen', position: 1 },
  { name: 'Sonderbedingungen für das Online-Banking', position: 2 },
  { name: 'Sonderbedingungen für den Lastschriftverkehr', position: 3 },
  { name: 'Sonderbedingungen für den Überweisungsverkehr', position: 4 },
  {
    name: 'Sonderbedingungen für die Ausführung von Echtzeit-Überweisungen',
    position: 5,
  },
  {
    name: 'Sonderbedingungen für die Ausführung von Echtzeit-Überweisungen mittels Sammelaufträgen',
    position: 6,
  },
  { name: 'Sonderbedingungen für die girocard (Debitkarte)', position: 7 },
  {
    name: 'Sonderbedingungen für die Nutzung des elektronischen Postfachs',
    position: 8,
  },
];

@Component({
    selector: 'app-einsicht',
    templateUrl: './einsicht.component.html',
    styleUrls: ['./einsicht.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        NgStyle,
        MatCardImage,
        TranslationDirective,
        TranslateModule,
        LoaderComponent,
        BankacountsComponent,
        KreditcheckabschlussComponent,
        FormsModule,
        ReactiveFormsModule,
        MatProgressSpinner,
        MatAccordion,
        MatExpansionPanel,
        MatExpansionPanelHeader,
        MatExpansionPanelTitle,
        MatTable,
        MatColumnDef,
        MatCellDef,
        MatCell,
        MatButton,
        MatIcon,
        MatRowDef,
        MatRow,
        MatCheckbox,
    ],
})
export class EinsichtComponent extends QuestionTemplate implements OnInit, AfterViewInit, OnDestroy {
  step = 1;
  identityCheckStatus = 'inprogress';
  imagelang: string;
  descriptionImagePath: string;
  lang: string;
  isAdmin: boolean;
  panelOpenState = false;
  panelOpenState2 = false;
  panelOpenState3 = false;
  isDownloading = false;
  verDataSource: Dokument[];
  dokDataSource: Dokument[];
  agbDataSource: Dokument[];
  displayedColumns: string[] = ['name', 'download'];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  baseUrl: string;
  getWebformStatus = true;
  loading: boolean;
  finapiResult: any;

  constructor(
    private dialog: MatDialog,
    auth: AuthService,
    questionService: QuestionService,
    routeActive: ActivatedRoute,
    private dataService: DataService,
    private finapiService: FinapiService,
    private router: Router,
    private adminService: AdminService,
    public admin: AdminService
  ) {
    super(auth, questionService, routeActive);
    this.questionName = 'einsicht';
    this.verDataSource = VERTRAEGE_DATA;
    this.dokDataSource = DOKUMENTE_DATA;
    this.agbDataSource = AGBS_DATA;
    this.imagelang = this.auth.user.lang;
    this.lang = this.auth.user.lang;
    if (this.imagelang === 'mcc') {
      if (this.auth.user.natPersonen[this.auth.index].BranchenInfo.Fachgebiet == 'Zahnarztpraxen') {
        this.descriptionImagePath = 'assets/img/dokumente-zahnarzt.png';
      } else {
        this.descriptionImagePath = 'assets/img/dokumente-carecapital.png';
      }
    } else {
      this.descriptionImagePath = 'assets/img/dokumente-odfin.png';
    }
    if (this.auth.user?.kundenNummer) {
      this.step = 4;
      this.adminService.setShowCancelButton(false);
      this.adminService.updateSubStatus(this.auth.user._id, 'documents').subscribe(() => {});
    } else if (
      typeof this.auth.user.finapi?.creditCheck?.derivedCreditLimitAmount != 'undefined' ||
      (!this.auth.user.questions[0]?.answer?.kontoWechselService &&
        !this.auth.user.questions[0]?.answer?.kontokorrentkredit &&
        !this.auth.user.questions[0]?.answer?.kreditKarte)
    ) {
      this.step = 3;
    }
  }

  ngOnDestroy(): void {
    this.getWebformStatus = false;
  }

  /**
   * Init variables und FormGroup machen
   */
  override ngOnInit() {
    super.ngOnInit();
    this.isAdmin = this.auth.user.rol == Rol.admin || this.auth.user.rol == Rol.adminBB;
    this.updateVertraegeList(this.finapiResult);
    this.baseUrl = location.origin;
    this.answerFormGroup = new FormGroup({
      heruntergeladen: new FormControl(null),
      bestätigt: new FormControl(null, [Validators.requiredTrue]),
    });
    this.loadValues();
  }

  ngAfterViewInit() {
    let container = document.getElementById('FinApiWeb');
    if (container) {
      this.createWebForm(container);
    }
  }

  async openPdf(element) {
    const normalizeString = (str) => str.replace(/\s+/g, '').toLowerCase();
    const documents = (await firstValueFrom(this.dataService.getMyDocuments())).documents;
    const document = documents.find((doc) => {
      return normalizeString(doc.documentname) === normalizeString(element.name);
    });
    if (document) {
      const fileId = document.fileId;
      this.admin.getDocuments(fileId).subscribe((blob) => {
        this.openPdfs(blob);
      });
    } else {
      console.error('Document not found');
    }
  }

  openPdfs(blob) {
    const url = window.URL.createObjectURL(blob);

    const dialogData: { pdfSrc: any } = { pdfSrc: url };
    this.dialog
      .open(PdfViewBoxComponent, {
        data: dialogData,
      })
      .afterClosed()
      .subscribe(() => {
        window.URL.revokeObjectURL(url);
      });
  }

  // openPdf(documentNr: number) {
  // this.loading = true;
  // this.dataService.getDocument(documentNr).subscribe((res) => {
  // const blob = new Blob([res], { type: "application/pdf" });
  // const url = window.URL.createObjectURL(blob);
  // this.dialog
  // .open(PdfViewBoxComponent, {
  // data: { pdfSrc: url },
  // })
  // .afterClosed()
  // .subscribe(() => {
  // this.loading = false;
  // });
  // });
  // }

  openAgbPdf(documentNr: number) {
    this.dataService.getRechtlichesDocument(documentNr).subscribe((res) => {
      const blob = new Blob([res], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      this.dialog
        .open(PdfViewBoxComponent, {
          data: { pdfSrc: url },
        })
        .afterClosed()
        .subscribe(() => {});
    });
  }

  downloadZip() {
    this.isDownloading = true;
    this.dialog
      .open(DownloadDialogBoxComponent, {
        data: {
          isDownloading: this.isDownloading,
        },
      })
      .afterClosed();
    this.dataService.downloadAllEinsichtDocuments().subscribe(
      (res) => {
        const blob = new Blob([res], { type: 'application/zip' });
        let name = 'Dokumente.zip';

        saveAs(blob, name);
        this.answerFormGroup.controls['heruntergeladen'].setValue(true);
      },
      (err) => {
        this.isDownloading = false;
      },
      () => {
        this.isDownloading = false;
        this.dialog.closeAll();
        this.dialog.open(DownloadDialogBoxComponent, {
          data: {
            isDownloading: this.isDownloading,
          },
        });
        setTimeout(() => {
          this.dialog.closeAll();
        }, 2000);
      }
    );
  }

  isOdfin(): boolean {
    return this.auth.user.lang == 'odfin' ? true : false;
  }

  // vertraegeTitel(): string {
  // let contractTitles: string[] = [];
  // this.verDataSource
  // .filter((contract) => !(this.isOdfin() && contract.position === 6))
  // .forEach((contract) => contractTitles.push(contract.name));
  // return contractTitles.join("\r\n");
  // }
  vertraegeTitel(): string {
    const contractTitles: string[] = this.verDataSource.map((contract) => contract.name);
    return contractTitles.join('\r\n');
  }

  updateVertraegeList(result) {
    const shouldFilterPosition = (positionsToFilter, condition) => {
      return condition ? positionsToFilter : [];
    };

    const isCreditLimitZero =
      result?.derivedCreditLimitAmount === 0 || this.auth.user.finapi?.creditCheck?.derivedCreditLimitAmount === 0;
    const isCreditCardLimitZero =
      result?.derivedCreditCardLimitAmount === 0 ||
      this.auth.user.finapi?.creditCheck?.derivedCreditCardLimitAmount === 0;

    const userAnswers = this.auth.user.questions[0]?.answer;

    let positionsToFilter = [
      ...shouldFilterPosition(
        [6],
        userAnswers?.kontokorrentkredit === false || (userAnswers?.kontokorrentkredit === true && isCreditLimitZero)
      ),
      ...shouldFilterPosition(
        [7],
        userAnswers?.kreditKarte === false || (userAnswers?.kreditKarte === true && isCreditCardLimitZero)
      ),

      ...shouldFilterPosition([1], !this.auth.isJurPerson()), // ElektronischesPostfach only for JurPerson
      ...shouldFilterPosition(
        [12, 13],
        !this.auth.isJurPerson() || this.auth.user.JurPerson?.JurDaten?.GbrArt !== 'GbR ohne Haftungsbeschraenkung'
      ),
      ...shouldFilterPosition(
        [4],
        !this.auth.isJurPerson() || this.auth.user.JurPerson?.JurDaten?.GbrArt === 'GbR ohne Haftungsbeschraenkung'
      ),
      ...shouldFilterPosition([14], this.auth.isJurPerson()), // Kundenstamm_Vertrag only for natPerson
      ...shouldFilterPosition([8], this.isOdfin()),
      ...shouldFilterPosition([9], !this.auth.isJurPerson()),
    ];
    positionsToFilter.push(...shouldFilterPosition([10], !this.auth.isJurPerson()));
    positionsToFilter.push(...shouldFilterPosition([15], userAnswers.abtretungsvertrag === undefined));
    if (this.auth.isJurPerson()) {
      const { JurDaten } = this.auth.user.JurPerson;

      positionsToFilter.push(
        ...shouldFilterPosition(
          [12, 13],
          JurDaten?.RechtsformAuspraegung === 'Gesellschaft mit beschränkter Haftung (GmbH)'
        )
      );
    }

    this.verDataSource = VERTRAEGE_DATA.filter((dokument) => !positionsToFilter.includes(dokument.position));
  }

  async createWebForm(container: HTMLElement) {
    console.log('container new :', container);
    var token: string;
    let self = this;
    this.finapiService.getWebForm(this.auth.user.lang).subscribe({
      next: async (res) => {
        if (res.processToken) {
          this.identityCheckStatus = 'success';
        }
        token = await Promise.resolve(res.importData.webFormId);
        this.finapiService.PROCESS_TOKEN = res.processToken;
        this.finapiService.ACCESS_TOKEN = res.accessToken;
        this.finapiService.IMPORT_ID = res.importData.importId;
        window.sessionStorage.setItem('accessToken', res.accessToken);

        /*if(window.sessionStorage.getItem('identityCheckStatus')){
      /!*TODO:after deploy check does step heave right value and is there need to have other values*!/
      this.step = 2;
    } else {*/
        load(
          container,
          {
            token: token,
            targetUrl: environment.finapi.targetUrl,
          },
          {
            onComplete: function () {
              self.step = 2;
              console.log('onComplete  :', token);
            },
            onFail: function () {
              console.log('Einsicht: onFail', token);
              console.log(self.baseUrl + '/info/error-page', 'url for redirect');
              window.location.href = self.baseUrl + '/info/error-page';
            },
            onAbort: function () {
              console.log('Einsicht: onAbort', token);
              console.log(self.baseUrl + '/einsicht', 'url for redirect');
              window.location.href = self.baseUrl + '/einsicht';
            },
            onLoadError: function () {
              console.log('Einsicht: onLoadError', token);
              console.log(self.baseUrl + '/info/error-page', 'url for redirect');
              window.location.href = self.baseUrl + '/info/error-page';
            },
          }
        );
        this.getWebFormStatus(this.finapiService.ACCESS_TOKEN, res.importData.webFormId);
        /*}*/
      },
      error: (err) => {
        console.log(err);
        //this.adminService.updateSubStatus(this.auth.user._id, "finapiStop");
        if (err.error.blockedUser) {
          this.router.navigateByUrl('/info/last-identity-error');
        } else {
          this.router.navigateByUrl('/info/error-page');
        }
      },
    });
  }

  finalize() {
    this.step = 3;
    console.log('finalize: ', this.step);
  }

  getResDocuments(finapiResult) {
    this.finapiResult = finapiResult;
  }

  showDocuments() {
    this.updateVertraegeList(this.finapiResult);
    this.step = 4;
    this.adminService.setShowCancelButton(false);
    this.adminService.updateSubStatus(this.auth.user._id, 'documents').subscribe(() => {});
  }

  archiveDoks() {
    this.dataService.archiveAlldocuments().subscribe(() => {
      console.log('archive Done');
    });
  }

  saveDoks() {
    this.dataService.saveAlldocumentsInDB().subscribe(() => {
      console.log('archive Done');
    });
  }

  private getWebFormStatus(accessToken: String, webFormId: String) {
    setTimeout(() => {
      this.finapiService.getWebFormStatus(accessToken, webFormId).subscribe({
        next: async (res) => {
          if ((res.status === 'NOT_YET_OPENED' || res.status === 'IN_PROGRESS') && this.getWebformStatus) {
            this.getWebFormStatus(accessToken, webFormId);
          }
          if (res.status === 'COMPLETED_WITH_ERROR') {
            if (res.payload.errorCode === 'NO_ACCOUNTS_FOR_TYPE_LIST') {
              this.router.navigateByUrl('/info/no-result-page');
            }
          }
        },
        error: (err) => {
          console.log(err, 'err of getWebFormStatus');
          // this.adminService.updateSubStatus(this.auth.user._id, "finapiStop");
          this.router.navigateByUrl('/info/error-page');
        },
      });
    }, 3000);
  }
}
