import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper, MatStepperIcon, MatStep } from '@angular/material/stepper';
import { ActivatedRoute, NavigationStart, Router, RouterLink, RouterOutlet } from '@angular/router';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { KeycloakService } from 'keycloak-angular';
import { DefaultDialogBoxComponent } from 'src/app/component/dialog-boxes/default-dialog-box/default-dialog-box.component';
import { Rol, Status, User } from 'src/app/models/user';
import { AdminService } from 'src/app/services/admin.service';
import { Agree21Service } from 'src/app/services/agree21.service';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { NotificationService } from 'src/app/services/notification.service';
import { QuestionService } from 'src/app/services/question.service';
import { environment } from 'src/environments/environment';
import { PepDialogBoxComponent } from '../../component/dialog-boxes/pep-dialog-box/pep-dialog-box.component';
import { QuestionTemplate } from './questions/question-template';
import { NgIf, NgClass, NgFor } from '@angular/common';
import { MatToolbar, MatToolbarRow } from '@angular/material/toolbar';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatIconButton, MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatCardImage } from '@angular/material/card';
import { TranslationDirective } from '../../component/translata-modal/translation.directive';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

/**
 * Diese Komponente ist der Rahmen, in den alle Fragen des Fragebogens geladen werden.
 * Hier befinden sich die Schaltflächen Sichern/Nächste und Zurück.
 * Alle Fragekomponenten müssen die Klasse "question-template" extend. (hirearchi)
 */
@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss'],
  standalone: true,
  imports: [
    MatProgressSpinnerModule,
    NgIf,
    MatToolbar,
    MatToolbarRow,
    MatMenu,
    MatMenuItem,
    RouterLink,
    TranslateModule,
    MatIconButton,
    MatMenuTrigger,
    MatIcon,
    NgClass,
    MatStepper,
    MatStepperIcon,
    NgFor,
    MatStep,
    MatCardImage,
    TranslationDirective,
    RouterOutlet,
    MatButton,
  ],
})
export class QuestionnaireComponent implements OnInit {
  private currentQuestionIndex: number;
  stepNumber = 0;
  stepActive: boolean;
  stepPage: boolean;
  showHeader: boolean;
  showImage: boolean;
  showIconInButton: boolean;
  nextButtonTitle: string;
  isAbschluss: boolean;
  description: { title: string; text: string };
  descriptionImagePath: string;
  logoImagePath: string;
  imagelang: string;
  answerFormGroup: FormGroup;
  user: User;
  isAdminOrConsultant: boolean;
  pathIsEinsicht = false;
  steps: any[];
  logoImage;
  // steps: any[] = [
  //   { label: "Produktauswahl" },
  //   { label: "dokumente" },
  //   { label: "Stammdaten & Rechtliches" },
  //   { label: "Geschäftskonto eröffnen" },
  // ];
  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild('myStep') myStep: MatStepper;
  favIcon: HTMLLinkElement | null = document.querySelector('#favIcon') as HTMLLinkElement;
  favDescription: HTMLElement | null = document.querySelector('#favDescription');
  bodyElement: HTMLElement | null = document.querySelector('#body');
  juriPerson: boolean = false;
  isLoading: boolean;
  isSaveAndContinueDisabled = false;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private questionService: QuestionService,
    private auth: AuthService,
    private notification: NotificationService,
    private translate: TranslateService,
    private adminService: AdminService,
    private dataService: DataService,
    private agree21Service: Agree21Service,
    private keycloakService: KeycloakService
  ) {
    this.stepPage = false;
    this.showHeader = false;
    this.showIconInButton = false;
    this.isAbschluss = false;
    this.description = { title: '', text: '' };
    this.nextButtonTitle = '';
    this.descriptionImagePath = '';
    this.logoImagePath = '';

    // Handle History navigation
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart && event.navigationTrigger === 'popstate')
        this._init(event.url.split('/')[2]);
    });
  }

  ngOnInit() {
    if (this.auth.isJurPerson()) {
      this.steps = [
        { label: 'Produktauswahl' },
        { label: 'Stammdaten' },
        { label: 'Dokumente' },
        { label: 'Rechtliches' },
        { label: 'Geschäftskonto eröffnen' },
      ];
    } else {
      this.steps = [
        { label: 'Produktauswahl' },
        { label: 'Stammdaten & Rechtliches' },
        { label: 'Geschäftskonto eröffnen' },
      ];
    }

    this.user = this.auth.user;
    this.imagelang = this.user.lang;
    const logoImageOD = 'assets/img/Bankhaus_Bauer_Logo.svg';
    const logoImageMCC = 'assets/img/Bankhaus_Bauer_Logo1.svg';
    this.imagelang === 'mcc' ? (this.logoImagePath = logoImageMCC) : (this.logoImagePath = logoImageOD);
    this.translate.use(this.user.lang.toLowerCase());
    this.isAdminOrConsultant = this.user.rol !== Rol.client;
    const question = this.questionService.getLastQuestion(this.auth.user.questions);
    if (this.route.firstChild) {
      const path = this.route.firstChild.snapshot.url[0].path;
      if (path === question.route) this._init(path);
    }
    this.router.navigate([question.route], { relativeTo: this.route }).then(() => {
      this._init(question.route);
      this.stepNumber = question.step || 0;
    });

    /*this.router.events.subscribe(event => {
      if (event instanceof NavigationStart){
        if(event.url === '/zusammenfassung' || event.url === '/blanko-dokumente'){
          this.stepper._steps.first.interacted = true;
          this.stepper._steps.first.completed = true;
          console.log(this.stepper._steps.first , 'first step');
        }
      }
    });*/
    this.juriPerson = this.auth.isJurPerson();

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.isSaveAndContinueDisabled = false; // Reset button state on navigation
      }
    });
  }

  private async _init(path: string) {
    const logoImagePathOD = 'assets/img/menu-logo-odfin.svg';
    const logoImagePathCC = 'assets/img/menu-logo-carecapital.svg';
    const logoImageOD = 'assets/img/Bankhaus_Bauer_Logo.svg';
    const logoImageMCC = 'assets/img/Bankhaus_Bauer_Logo1.svg';
    const questionObj = await this.questionService.getQuestionObjByRoute(path);
    this.currentQuestionIndex = this.questionService.getQuestionIndexByRoute(path);
    this.stepPage = questionObj?.stepPage || false;
    this.showHeader = questionObj?.showHeader || false;
    this.description = questionObj?.description || { title: '', text: '' };
    this.nextButtonTitle = questionObj?.nextButtonTitle || '';
    this.showIconInButton = questionObj?.showIconInButton || false;
    this.pathIsEinsicht = questionObj?.route === 'einsicht';

    if (questionObj?.imagePath) {
      if (this.imagelang === 'mcc') {
        this.logoImagePath = logoImagePathCC;
        this.logoImage = logoImageMCC;
        this.favIcon!.href = 'assets/img/icons-input/favicon-careCapital.svg';
        this.favDescription!.innerHTML = 'Kontoeröffnung - CareCapital';
        this.bodyElement!.style.cssText = "font-family: 'Jost'";
        this.descriptionImagePath =
          questionObj.imagePath +
          (this.auth.user.natPersonen[this.auth.index].BranchenInfo.Fachgebiet == 'Zahnarztpraxen'
            ? '-zahnarzt.png'
            : '-carecapital.png');
      } else if (this.imagelang === 'odfin') {
        this.logoImage = logoImageOD;
        this.descriptionImagePath = questionObj.imagePath + '-odfin.png';
        this.logoImagePath = logoImagePathOD;
        this.favIcon!.href = 'assets/img/icons-input/favicon-optaData.svg';
        this.favDescription!.innerHTML = 'opta data Gruppe - Kontoeröffnung';
        this.bodyElement!.style.cssText = "font-family: 'Poppins'";
      }
    }

    this.showImage = !!questionObj?.imagePath;
    this.stepNumber = questionObj?.step || 0;
    this.isAbschluss = this.stepNumber === 3;

    if (questionObj?.component) {
      this.answerFormGroup = questionObj.component.answerFormGroup;
    }
  }

  handleSaveAndDisableButton() {
    this.isSaveAndContinueDisabled = true;
    this.saveAndContinue();
  }

  isButtonDisabled(): boolean {
    const component = this.questionService.getComponent(this.currentQuestionIndex);

    if (component && component.questionName === 'bank-wahl') {
      const kontokorrentkredit = this.answerFormGroup.controls?.['kontokorrentkredit'].value;

      if (this.auth.user.lang === 'odfin') {
        const abtretungsvertrag = this.answerFormGroup.controls?.['abtretungsvertrag'].value;

        // Disable the button if kontokorrentkredit is true and abtretungsvertrag is not true
        return kontokorrentkredit && !abtretungsvertrag;
      }

      // If the language is not 'odfin', don't apply the abtretungsvertrag check
      return false;
    }

    // For other questions, the button is disabled based on the form's validity
    return this.answerFormGroup && !this.answerFormGroup.valid;
  }

  async saveAndContinue() {
    const component = this.questionService.getComponent(this.currentQuestionIndex);

    if (component && component.questionName === 'bank-wahl') {
      const { kreditKarte, kontokorrentkredit, kontoWechselService } = this.answerFormGroup.controls;

      const kreditKarteValue = kreditKarte?.value;
      const kontokorrentkreditValue = kontokorrentkredit?.value;
      const kontoWechselServiceValue = kontoWechselService?.value;
      if (!kreditKarteValue || !kontokorrentkreditValue || !kontoWechselServiceValue) {
        await this.showConfirmationDialog(
          'PRODUKTAUSWAHL.BESTÄTIGUNG_PRODUKTAUSWAHL_HEADER',
          'PRODUKTAUSWAHL.BESTÄTIGUNG_PRODUKTAUSWAHL_TEXT',
          () => this.saveAnswer(component)
        );
      } else {
        // In all other cases, just save the answer without showing the confirmation dialog
        this.saveAnswer(component);
      }
      this.adminService.updateSubStatus(this.auth.user._id, 'inProcess').subscribe(() => {});
    } else if (component && component.questionName === 'blanko-dokumente') {
      const offentlichesAmt = this.answerFormGroup.controls?.['offentlichesAmt'].value;

      if (offentlichesAmt === 'true') {
        await this.showPepDialog(() => {
          this.isLoading = true;
          this.adminService.sendPepEmail(this.auth.user._id).subscribe(async () => {
            this.adminService.updateSubStatus(this.auth.user._id, 'pep').subscribe(() => {
              this.auth.changeStatus(this.auth.user._id, Status.inactive).subscribe(() => {
                this.isLoading = false;
                window.location.href = environment.oneDashboardURL;
              });
            });
          });
        });
      } else {
        this.saveAnswer(component);
      }
    } else if (component && component.questionName === 'einsicht') {
      if (!this.auth.user?.giroCardOrdered) {
        this.auth.refreshUser().subscribe(async (user) => {
          const index = this.auth.findFlaggedNaturalPerson(this.user.natPersonen);
          const Personennummer = user.isJurPerson
            ? user.natPersonen[index].BBKundennummer
            : this.agree21Service?.PERSONEN_NUMMER || user.kundenNummer;

          await this.processGirocard(this.auth.user.isJurPerson, Personennummer);
        });
      } else {
        console.log('schon gemacht - postGirocardOnlineBanking');
      }
      this.saveAnswer(component);
      this.dataService.archiveAlldocuments().subscribe(() => {});
      this.auth.changeStatus(this.auth.user._id, Status.finish).subscribe(() => {});

      if (this.auth.user.questions[0].answer.kontoWechselService === true) {
        this.adminService.updateSubStatus(this.auth.user._id, 'kws').subscribe(() => {});
      }
    } else if (component) {
      this.saveAnswer(component);
    }
  }

  async processGirocard(isJurPerson: boolean, Personennummer: number) {
    const methodName = isJurPerson ? 'girocardOnlineBankingForJurPerson' : 'postGirocardOnlineBanking';
    const action = isJurPerson ? 'JurPerson' : 'NatPerson';

    this.agree21Service[methodName](Personennummer, this.agree21Service.IBAN, this.agree21Service.IBAN).subscribe(
      (result) => {
        if (!result) {
          throw new Error(`Failed - girocardOnlineBankingFor${action}`);
        }
        console.log(`Success - girocardOnlineBankingFor${action}`);
      }
    );
  }

  async showConfirmationDialog(headerText, subText, callback) {
    const answer = await this.dialog
      .open(DefaultDialogBoxComponent, {
        data: {
          headerText,
          subText,
        },
      })
      .afterClosed()
      .toPromise();

    if (answer === true) {
      callback();
    } else {
      this.isSaveAndContinueDisabled = false;
    }
  }

  async showPepDialog(callback) {
    const answer = await this.dialog.open(PepDialogBoxComponent).afterClosed().toPromise();

    if (answer === true) {
      callback();
    }
  }

  async saveAnswer(component: QuestionTemplate) {
    if (component && component.answerFormGroup && component.answerFormGroup.valid)
      component.saveAnswer().subscribe(
        async () => {
          await this.nextStep();
        },
        () => {
          setTimeout(() => window.location.reload(), 1000);
        }
      );
    else {
      await this.nextStep();
    }
  }

  async nextStep() {
    const component = this.questionService.getComponent(this.currentQuestionIndex);
    let nextRoute = this.questionService.getRouteName(this.currentQuestionIndex + 1);
    // not allow spring questions on double click
    if (nextRoute && component && (!component.answerFormGroup || component.answerFormGroup.valid)) {
      this.router.navigate([nextRoute], { relativeTo: this.route });
      await this._init(nextRoute);
    }
  }

  logout() {
    this.keycloakService.logout();
  }

  showCancelButton() {
    return this.adminService.getShowCancelButton();
  }
}
